import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateSigner } from "../SmartContract";

const AuthProtected = (props) => {
  const { user } = useSelector(state => ({
      user: state.Login.user,
  }));

  /*
    redirect is un-auth access protected routes via url
    */
  if (Object.keys(user).length === 0) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };