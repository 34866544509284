import { performTransaction } from "../pages/Projects/performTransaction";
import { APPROVERS } from "../SmartContract";
import { contract } from "../SmartContract";

export const testFunction = (testCase) =>{
    
    switch (testCase) {
        case 1:
          // Handle test case 1
          testcase1();
          console.log("Test case 1 executed");
          break;
        case 2:
          // Handle test case 2
          testcase2();
          console.log("Test case 2 executed");
          break;
        case 3:
          // Handle test case 3
          testcase3();
          console.log("Test case 2 executed");
          break;
        case 4:
          // Handle test case 2
          testcase4();
          console.log("Test case 4 executed");
          break;
        case 5:
          // Handle test case 2
          testcase5();
          console.log("Test case 5 executed");
          break;
        default:
          console.log("Invalid test case");
      }

    async function testcase1(){
        console.log("testFunction 1")
        //normal 4 action 4 use test case
        const ACTION = [
            {
                "name": "SAMPLE_REPORT_SUMBIT",
                "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY],
                "numOfExecutes": 1,
                "numOfConfirmations": 2
            }
        ]

        // Define your function parameters
        //step1: create project
        const createProjectParameters = [
            "test1 (1 action, submit & view report demonstrating)",
            "noah",
            "noah_lab",
            "noah",
            "hong kong",
            "my_site",
            ACTION
        ];

        await performTransaction(createProjectParameters,"createProject");
        // contract.on('MyLogEvent2', (sender, batchIDCount, event) => {
        //     console.log('Sender:', sender);
        //     console.log('Batch ID Count:', batchIDCount);
        //     console.log('Event:', event);
        // });
        console.log("created test1")
        const result = await contract.projectIDCount();
        console.log("projectIDCount",result)
        try{
             // step2: create batch
            const createBatchParameters = [
                Number(result)-1,
                "Cement",
                "noah",
                "test1, projectID:"+(Number(result)-1),
                1000,
                "test1",
                "test1"
            ]; 
            await performTransaction(createBatchParameters,"createBatch");
        } catch(e){console.log(e)}
       
        //step3: perform transaction to certain levevl
        window.location.href = ("...");

    }

    async function testcase2(){
      console.log("testFunction 2")
      //normal 4 action 4 use test case
      const ACTION = [
        {
          "name": "SAMPLE_SUMBIT",
          "approvers": [APPROVERS.DEFYPAY],
          "numOfExecutes": 1,
          "numOfConfirmations": 1
        },
        {
            "name": "SAMPLE_REPORT_SUMBIT",
            "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY],
            "numOfExecutes": 1,
            "numOfConfirmations": 2
        }
      ]

      // Define your function parameters
      //step1: create project
      const createProjectParameters = [
          "test2 (2 actions, submit transaction demonstrating)",
          "noah",
          "noah_lab",
          "noah",
          "hong kong",
          "my_site",
          ACTION
      ];

      await performTransaction(createProjectParameters,"createProject");
      // contract.on('MyLogEvent2', (sender, batchIDCount, event) => {
      //     console.log('Sender:', sender);
      //     console.log('Batch ID Count:', batchIDCount);
      //     console.log('Event:', event);
      // });
      const result = await contract.projectIDCount();
      console.log("projectIDCount",result)
      try{
           // step2: create batch
          const createBatchParameters = [
              Number(result)-1,
              "Cement",
              "noah",
              "test2, projectID:"+(Number(result)-1),
              1000,
              "test2",
              "test2"
          ]; 
          await performTransaction(createBatchParameters,"createBatch");
      } catch(e){console.log(e)}
     
      //step3: perform transaction to certain levevl
      window.location.href = ("...");

    }

    async function testcase3(){
      console.log("testFunction 3")
      //normal 4 action 4 use test case
      const ACTION = [
        {
          "name": "SAMPLE_SUMBIT",
          "approvers": [APPROVERS.DEFYPAY,APPROVERS.HS_WORKER],
          "numOfExecutes": 2,
          "numOfConfirmations": 2
      },
      {
        "name": "SAMPLE_ARRIVEL_LAB",
        "approvers": [APPROVERS.LAB],
        "numOfExecutes": 2,
        "numOfConfirmations": 1
      },
      {
          "name": "SAMPLE_REPORT_SUMBIT",
          "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY],
          "numOfExecutes": 2,
          "numOfConfirmations": 2
      }
      ]

      // Define your function parameters
      //step1: create project
      const createProjectParameters = [
          "test3 (3 actions, submit &confirm transaction demonstrating)",
          "noah",
          "noah_lab",
          "noah",
          "hong kong",
          "my_site",
          ACTION
      ];

      await performTransaction(createProjectParameters,"createProject");
      // contract.on('MyLogEvent2', (sender, batchIDCount, event) => {
      //     console.log('Sender:', sender);
      //     console.log('Batch ID Count:', batchIDCount);
      //     console.log('Event:', event);
      // });
      const result = await contract.projectIDCount();
      console.log("projectIDCount",result)
      try{
           // step2: create batch
          const createBatchParameters = [
              Number(result)-1,
              "Cement",
              "noah",
              "test3, projectID:"+(Number(result)-1),
              1000,
              "test3",
              "test3"
          ]; 
          await performTransaction(createBatchParameters,"createBatch");
      } catch(e){console.log(e)}
     
      //step3: perform transaction to certain levevl
      window.location.href = ("...");

    }

    async function testcase4(){
      console.log("testFunction 4")
      //normal 4 action 4 use test case
      const ACTION = [
        {
          "name": "SAMPLE_SUMBIT",
          "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY,APPROVERS.HS_WORKER, APPROVERS.WORKER],
          "numOfExecutes": 1,
          "numOfConfirmations": 4
      },
      {
          "name": "SAMPLE_TRANSFER",
          "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY,APPROVERS.HS_WORKER, APPROVERS.WORKER],
          "numOfExecutes": 1,
          "numOfConfirmations": 4
      },
      {
          "name": "SAMPLE_ARRIVEL_LAB",
          "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY,APPROVERS.HS_WORKER, APPROVERS.WORKER],
          "numOfExecutes": 1,
          "numOfConfirmations": 4
      },
      {
          "name": "SAMPLE_REPORT_SUMBIT",
          "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY,APPROVERS.HS_WORKER, APPROVERS.WORKER],
          "numOfExecutes": 1,
          "numOfConfirmations": 4
      }
      ]

      // Define your function parameters
      //step1: create project
      const createProjectParameters = [
          "test4 (4 actions, each transaction 4 users demonstrating)",
          "noah",
          "noah_lab",
          "noah",
          "hong kong",
          "my_site",
          ACTION
      ];

      await performTransaction(createProjectParameters,"createProject");
      // contract.on('MyLogEvent2', (sender, batchIDCount, event) => {
      //     console.log('Sender:', sender);
      //     console.log('Batch ID Count:', batchIDCount);
      //     console.log('Event:', event);
      // });
      const result = await contract.projectIDCount();
      console.log("projectIDCount",result)
      try{
           // step2: create batch
          const createBatchParameters = [
              Number(result)-1,
              "Cement",
              "noah",
              "test4, projectID:"+(Number(result)-1),
              1000,
              "test4",
              "test4"
          ]; 
          await performTransaction(createBatchParameters,"createBatch");
      } catch(e){console.log(e)}
     
      //step3: perform transaction to certain levevl
      window.location.href = ("...");

    }

    async function testcase5(){
      console.log("testFunction 5")
      //normal 4 action 4 use test case
      const ACTION = [
        {
            "name": "SAMPLE_SUMBIT",
            "approvers": [APPROVERS.WORKER, APPROVERS.HS_WORKER],
            "numOfExecutes": 1,
            "numOfConfirmations": 2
        },
        {
            "name": "SAMPLE_TRANSFER",
            "approvers": [APPROVERS.WORKER, APPROVERS.HS_WORKER],
            "numOfExecutes": 2,
            "numOfConfirmations": 2
        },
        {
            "name": "SAMPLE_ARRIVEL_LAB",
            "approvers": [APPROVERS.DEFYPAY,APPROVERS.LAB],
            "numOfExecutes": 3,
            "numOfConfirmations": 2
        },
        {
            "name": "SAMPLE_SCAN",
            "approvers": [APPROVERS.LAB],
            "numOfExecutes": 2,
            "numOfConfirmations": 1
        },
        {
            "name": "SAMPLE_REPORT_SUMBIT",
            "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY],
            "numOfExecutes": 2,
            "numOfConfirmations": 2
        }
    ]

      // Define your function parameters
      //step1: create project
      const createProjectParameters = [
          "test5 (5 actions, function tree demonstrating)",
          "noah",
          "noah_lab",
          "noah",
          "hong kong",
          "my_site",
          ACTION
      ];

      await performTransaction(createProjectParameters,"createProject");

      const result = await contract.projectIDCount();
      console.log("projectIDCount",result)
      try{
           // step2: create batch
          const createBatchParameters = [
              Number(result)-1,
              "Cement",
              "noah",
              "test5, projectID:"+(Number(result)-1),
              1000,
              "test5",
              "test5"
          ]; 
          await performTransaction(createBatchParameters,"createBatch");
      } catch(e){console.log(e)}
     
      //step3: perform transaction to certain levevl
      window.location.href = ("...");

    }
}
