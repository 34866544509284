import { signContract, signContractDefypay,contract,contractDefypay ,provider, signer, updateSigner } from '../../SmartContract';
import Swal from 'sweetalert2';
const { formatUnits } = require('ethers');

export async function performTransaction(functionParameters, operation) {
    console.log("perform Transaction", operation)
    console.log("signer",signer.address)

    if(operation === "submitTransaction"||operation === "confirmTransaction")
    {
        Swal.fire({
            title: 'Please Wait !',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    }

    const feeData = (await provider.getBlock("latest"))
    const gasPrice = Number(feeData.baseFeePerGas);
    const price = gasPrice;
    console.log("feeData",price)

    console.log(`Current Gas Price: ${formatUnits(gasPrice, 'gwei')} Gwei`);

    try{
        var estimate = 1000000000;
        try{
            if (operation === "createProject")
                estimate = await signContract.createProject.estimateGas(...functionParameters);
            else if (operation === "createBatch")
                estimate = await signContract.createBatch.estimateGas(...functionParameters);
            else if (operation === "submitTransaction")
                estimate = await signContractDefypay.submitTransaction.estimateGas(...functionParameters);
            else if (operation === "confirmTransaction")
                estimate = await signContractDefypay.confirmTransaction.estimateGas(...functionParameters);
            else if (operation === "clearAllStorage")
                estimate = await signContractDefypay.clearAllStorage.estimateGas();
            console.log(`Estimated Gas: ${estimate.toString()}`);
        }catch(e)
        {console.log(operation+" cant estimate gas:", e)
        }

        let transactionParameters = {
            value: 0, // Amount of Ether to send (if needed)
            gasLimit: estimate, // Maximum amount of gas to use for the transaction
            gasPrice: price // Gas price in Gwei
        };
        
        var transaction;
        if (operation === "createProject")
            transaction = await signContract.createProject(...functionParameters, transactionParameters);
        else if (operation === "createBatch")
            transaction = await signContract.createBatch(...functionParameters, transactionParameters);
        else if(operation === "submitTransaction")
            transaction = await signContractDefypay.submitTransaction(...functionParameters, transactionParameters);
        else if(operation === "confirmTransaction")
            transaction = await signContractDefypay.confirmTransaction(...functionParameters,transactionParameters);
        else if(operation === "clearAllStorage")
            transaction = await signContractDefypay.clearAllStorage();
        // Wait for the transaction to be mined
        const receipt = await transaction.wait();
        console.log("Transaction receipt:", receipt);
        console.log(operation+"success")

        if(operation !== "createProject"&&operation !== "createBatch")
            window.location.reload();
        
        return receipt;
    }
    catch(e)
    {
        console.log("Transaction error", e, functionParameters);
        Swal.fire({
            title: "Action Failed!",
            icon: "error",
            allowOutsideClick: true,
        })
    }
    return false;
}