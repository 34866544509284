import React, { useEffect,useRef } from 'react';
import { Card, CardBody, Col, Container, Row, Button, Form, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// actions
import { resetLoginFlag } from "../../slices/thunks";

import logoLight from "../../assets/images/logo-light.png";
import { apiError, loginSuccess } from '../../slices/auth/login/reducer';
//import images
import {updateSigner } from '../../SmartContract';

const connectToMetaMask = async (dispatch) => {
    try {
        // Request account access
        console.log("Connecting to MetaMask");
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log('Connected to MetaMask. Account:', accounts[0]);
        // Now you can use the connected account for transactions or other operations
        console.log("connectToMetaMask")
        dispatch(loginSuccess({account: accounts[0]}));
        sessionStorage.setItem("authUser", accounts[0]);
        console.log(sessionStorage.getItem("authUser"))
    } catch (error) {
        console.error('Error connecting to MetaMask:', error);
        dispatch(apiError({ "data": 'Press the Metamask Icon on the top right corner to login.' }));
    }
};

const Login = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));

    useEffect(() => {
        console.log("check user login")
        if (Object.keys(user).length > 0 && user.account !== undefined && user.account !== "") {
            // route to main page.
            console.log("userAC:",user.account)
            console.log("Object.keys(user):",Object.keys(user))

            updateSigner(user.account);
            console.log("go to main page");
            navigate("/dashboard");
        }
    }, [user]);

    const { error, errorFlag, loading } = useSelector(state => ({
        error: state.Login.error,
        loading: state.Login.loading,
        errorFlag: state.Login.errorFlag,
    }));

    useEffect(() => {
        if (errorFlag) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, errorFlag]);

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="40" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">A Monitoring Material Test Blockchain System</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in DefyPay by connecting your wallet.</p>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-1">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    if (typeof window.ethereum !== 'undefined') {
                                                        // MetaMask is installed
                                                        // Proceed with connecting to MetaMask
                                                        console.log("route to the main page");
                                                        connectToMetaMask(dispatch);
                                                    } else {
                                                        // MetaMask is not installed
                                                        console.error('MetaMask is not installed');
                                                        dispatch(apiError({ "data": 'MetaMask is not installed' }));
                                                    }
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mt-0">
                                                    <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Connect Wallet
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>                    
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);