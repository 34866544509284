import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { useNavigate, Link } from 'react-router-dom';

//import images
import OverviewTab from './OverviewTab';
import DocumentsTab from './DocumentsTab';
import { contract } from '../../../SmartContract';

const Section = (props) => {
    const { id, name, Bid, tab } = props;
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [selectedBatchID, setSelectedBatchID] = useState(Bid);
    const [activeTab, setActiveTab] = useState(tab);

    useEffect(() => {
        if (!id) {
            navigate("/project-list");
        }
    }, [id]);

    useEffect(() => {
        console.log("contract.getProject(id)")
        const fetchData = async () => {
            try {
                const result = await contract.getProject(id);
                setData(result);
            } catch (error) {
                console.error('Error calling contract method:', error);
            }
        };

        fetchData();
    }, []);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    let localDatetime = "";
    if (data !== undefined && data["date"] !== undefined) {
        let dateStr = data["date"] + "";
        let date = new Date(dateStr * 1000); // Convert seconds to milliseconds
        localDatetime = date.toLocaleString(); // Convert to local date and time string
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mx-n4">
                        <div className="bg-soft-warning">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">Project: {name}</h4>
                                                    <div className="hstack gap-1 flex-wrap">
                                                        <div>Create Date : <span className="fw-medium">{localDatetime}</span></div>
                                                        <div className="vr"></div>
                                                        <div className="badge rounded-pill bg-info fs-12" onClick={() => {
                                                            navigate('/batch-create', { state: { id: id } })
                                                        }}>Create Batch</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>

                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <Link to={"/project-overview?id=" + id + "&name=" + name + "&tab=1"} className="link-success">
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' }, "text-body fw-semibold")}
                                                onClick={() => { toggleTab('1'); }}
                                            >
                                                Overview
                                            </NavLink>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to={"/project-overview?id=" + id + "&name=" + name + "&tab=2" + "&Bid=" + `${selectedBatchID}`} className="link-success">
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' }, "text-body fw-semibold")}
                                                onClick={() => { toggleTab('2'); }}
                                            >
                                                Batch
                                            </NavLink>
                                        </Link>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab data={data} setSelectedBatchID={setSelectedBatchID} setActiveTab={setActiveTab} />
                        </TabPane>
                        <TabPane tabId="2">
                            {data.length !== 0 &&
                                <DocumentsTab data={data} setSelectedBatchID={setSelectedBatchID} selectedBatchID={selectedBatchID} id={id} />
                            }
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;