import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Section from './Section';
import { useLocation } from 'react-router-dom';


const ProjectOverview = () => {
    document.title = "Defypay | Project";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const name = searchParams.get('name');
    let selectedBatchID = searchParams.get('Bid');//selected batchID

    if (selectedBatchID !== null) {
        selectedBatchID = BigInt(selectedBatchID);
    } else {
        selectedBatchID = "";
    }

    const tab = searchParams.get('tab');//batchID
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Section id={id} name={name} Bid={selectedBatchID} tab={tab} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectOverview;