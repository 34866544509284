import { useEffect, useState } from "react";
import { APPROVERS_MAP, contract ,signContractDefypay, signContract} from "../../../SmartContract";
import { useSelector } from 'react-redux';
import { performTransaction } from "../performTransaction";
import { Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import Swal from 'sweetalert2';
import { Dropzone, FileMosaic } from "@files-ui/react";
import axios from "axios";

const statusToDisplayname = [
    {
      "status": "INITIAL_NOT_SUBMIT",
      "displayName": "Submit new Transaction"
    },
    {
      "status": "PENDING_APPROVE",
      "displayName": "Pending approval"
    },
    {
        "status": "PENDING_MY_APPROVE",
        "displayName": "Pending my approval"
    },
    {
        "status": "SUBMIT_TXN",
        "displayName": "Submit transaction"
    },
    {
        "status": "EXECUTED",
        "displayName": "Transaction executed"
    },
    {
        "status": "END",
        "displayName": "Completed"
    }
]

const sampleOptions = [
    'Pressure cement test',
    'sample test 2',
    'sample test 3'
];

function ApprovalItem(props) {
    const [isApproved, setApproved] = useState(true);
    const [timestamp, setTimestamp] = useState(-1);

    const txnID = Number(props.txnID);
    const approver = props.approver;
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));
    console.log("txnID: " + txnID);
    console.log("txnID: " + (txnID === 0));
    console.log("approver: " + approver);
    console.log(APPROVERS_MAP[approver]["name"]);

    useEffect(() => {
        if (txnID !== undefined) {
            const fetchData = async () => {
                try {
                    console.log("call isApprovedFunc " + txnID);
                    const result = await signContractDefypay.isApprovedFunc(txnID, approver);
                    console.log("isApprovedFunc");
                    console.log(result[0],result[1]);
                    setApproved(result[0]);
                    const date = new Date(Number(result[1]) * 1000); // Convert to milliseconds by multiplying with 1000
                    const options = { 
                        hour12: true, 
                        hour: 'numeric', 
                        minute: 'numeric', 
                        second: 'numeric',
                      };
                      
                      const locale = 'en-US';
                      
                      const readableTime = date.toLocaleString(locale, options);
                    setTimestamp(readableTime)
                } catch (error) {
                    console.error('Error calling contract method:', error);
                }
            };

            fetchData();
        }
    }, [txnID])

    const handleConfirmAction = ()=>{
        console.log("handleConfirmAction",txnID);
        const functionParameters = [
            txnID
        ];
        try{
            performTransaction(functionParameters,"confirmTransaction");
        }catch (e) { console.log("error:", e) }
    }

    const isShowConfirmBtn = () => {
        console.log("isShowConfirmBtn")
        return approver.toLowerCase() == user.account && !isApproved && txnID !== 0;
    };

    return (
        <div>
            <div>{APPROVERS_MAP[approver]["name"]}{approver.toLowerCase() == user.account ? <span>(me)</span> : null}{" : " + isApproved}<br></br><small>{isApproved&&"Confirm time : "+timestamp}</small></div>
            <div className="d-flex flex-column align-items-center">
                {  isShowConfirmBtn()&&<button className="pull-right btn btn-primary btn-sm " style={{ marginTop:"10px", padding: '7px 16px', fontSize: '13px' }} type="submit" onClick={() => handleConfirmAction()}>Confirm transaction</button> }
            </div>
        </div>
    );
}

function TxnItem(props) {
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));

    console.log("==========");

    console.log(props.txn);
    const [funcID, setFuncID] = useState(props.txn[0]);
    const [txnInfo,setTxnInfo] = useState("undefined");
    const [preTxnStatus,setpreTxnStatus] = useState(false);
    const [submitData,setSubmitData] = useState("");
    const [files, setFiles] = useState([]);
    const [isShowDropzone,setIsShowDropzone] = useState(false);
    const projectID = props.projectID;
    const batchID = props.batchID;
    const material = props.batchMaterial;
    const status = props.txn[2];
    const [preTxnID,setPreTxnID] = useState(props.txn[3]);
    const txnID = props.txn[4];
    const approvers = props.approvers;
    const [rerender,setRerender] = useState(0)
    const [actionName, setActionName] = useState(null);
    const [timestamp, setTimestamp] = useState(-1);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const displayStatus = statusToDisplayname.map((d)=>{ if(d.status===status) return d.displayName})

    useEffect(()=>{
        setRerender(rerender+1)},
    [batchID])

    useEffect(() => {
        if (funcID > 0) {
            const getFunctionInfo = async () => {
                try {
                    console.log("functionID: ", funcID);
                    const response = await contract.funcMap(funcID);
                    if (response) {
                        setActionName(response[2]);
                    }
                    console.log("actionName: " + response[2]);
                }
                catch (e) { console.log(e) }
            };
            getFunctionInfo();
        }
        else {
            const getInitFuncID = async () => {
                try {
                    console.log("functionID: ", funcID);
                    const response = await signContract.getInitalFunc(projectID);
                    if (response) {
                        setActionName(response[2]);
                        setFuncID(response[0])
                    }
                }
                catch (e) { console.log(e) }
            };
            getInitFuncID();
        }

        const mapTransactionDataAndtimestamp= async () => {
            try{
                const response = await signContractDefypay.transactionMap(txnID);
                console.log("mapTransactionDataAndtimestamp",response)
                if(response)
                {
                    //map data
                    const cleanHexString = response[1].startsWith('0x') ? response[1].slice(2) : response[1];
                    const bytes = new Uint8Array(cleanHexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
                    const decodedString = new TextDecoder().decode(bytes);
                    setTxnInfo(decodedString);
                    //map timestamp
                    const date = new Date(Number(response[8]) * 1000); // Convert to milliseconds by multiplying with 1000
                    const options = { 
                        hour12: true, 
                        hour: 'numeric', 
                        minute: 'numeric', 
                        second: 'numeric',
                      };
                      
                      const locale = 'en-US';
                      
                    const readableTime = date.toLocaleString(locale, options);
                    setTimestamp(readableTime)
                    console.log("timestamp",timestamp)
                }
            }
            catch(e)
            {console.log(e, txnID)}
        };
        mapTransactionDataAndtimestamp();

        
        const checkPreTxnStatus = async () => {
            try{
                const response = await signContractDefypay.transactionMap(preTxnID);
                if(response)
                {
                    setpreTxnStatus(response[2]); 
                }
            }
            catch(e)
            {console.log(e)}
        };
        if(Number(preTxnID)>0)
            checkPreTxnStatus();
        else if (Number(preTxnID) === 0)
            setpreTxnStatus(true); 

    }, [txnID]);
    
    const handleSubmitAction = () => {
        if(actionName==="SAMPLE_REPORT_SUMBIT")
            setIsShowDropzone(true);
        else{
            handleTextSubmission();
        }
    }

    const handleTextSubmission = async() =>{
        let data = "No input data"
        if(submitData.length>0)
            data = submitData;
        let functionParameters = [
            funcID,
            data,
            projectID,
            material,
            batchID,
            preTxnID
        ];
        performTransaction(functionParameters, "submitTransaction");
    }

    const getApproverSection = () => {
        return approvers.map((d) => {
            return (
                <ApprovalItem key={d} txnID={txnID} approver={d} actionName={actionName} />
            )
        })
    };
    const onChangeHandler = (e, name) => {
        if(name==='submission data') setSubmitData(e.target.value);
    }

    const getSubmitButtonSection = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                {   
                    actionName!=="SAMPLE_REPORT_SUMBIT" && actionName!=="SAMPLE_SCAN" &&
                    <Input type="text" className="form-control" id="submitData" name="submitData" placeholder="Input submission data" value={submitData} style={{ marginTop:"10px", padding: '7px 16px', fontSize: '13px' }}  onChange={(e) => onChangeHandler(e, 'submission data')} />
                }
                {   
                    actionName === "SAMPLE_SCAN" && (
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle tag="button" className="btn btn-primary btn-sm" style={{ marginTop:"10px", padding: '7px 16px', fontSize: '13px' }}  id="dropdownMenuButton" caret>
                            {submitData.length===0? 'Select an option':submitData}
                        </DropdownToggle>
                        <DropdownMenu>
                            {sampleOptions.map((option, index) => (
                                <DropdownItem 
                                    key={index}
                                    onClick={() => onChangeHandler({ target: { value: option } }, 'submission data')}
                                >
                                {option}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                )}
                <button key={user.account} className="pull-right btn btn-primary btn-sm"  style={{ marginTop:"10px", padding: '7px 16px', fontSize: '13px' }} type="submit" onClick={() => handleSubmitAction()}>{actionName==="SAMPLE_REPORT_SUMBIT"? "Submit report":"Submit transaction"}</button>
            </div>
        )
    }

    const isShowSubmitBtn = () => {
        // console.log("isShowSubmitBtn",preTxnStatus,status,approvers.some(d => d.toLowerCase() === user.account.toLowerCase()))
        return preTxnStatus&&(status === "SUBMIT_TXN"||status === "INITIAL_NOT_SUBMIT") && approvers.some(d => d.toLowerCase() === user.account.toLowerCase());
    };

    const isValidUrl = (txnInfo)=>{
        return txnInfo.startsWith('http') ? true : false;
    }

    //all for dropzone
    const updateFiles = (incommingFiles) => {
        console.log("incomming files", incommingFiles);
        setFiles(incommingFiles);
    };

    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const uploadButtonHandler = async () => {
        console.log("uploadButtonHandler")
        Swal.fire({
            title: 'Please Wait !',
            html: "Uploading File",// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        try {
            const fileData = new FormData();
            fileData.append("file", files[0].file);
            console.log(typeof process.env.REACT_APP_PINATA_SECRET_API_KEY)
            const responseData = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                data: fileData,
                headers: {
                    pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                    pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
                    "ContentType": "multipart/form-data",
                }
            })
            Swal.close();
            // setuploadedFile(fileContent);
            const fileUrl = "http://gateway.pinata.cloud/ipfs/" + responseData.data.IpfsHash;
            console.log("url:", fileUrl);

            const functionParameters = [
                funcID,
                fileUrl,
                projectID,
                material,
                batchID,
                preTxnID
            ];
            performTransaction(functionParameters, "submitTransaction");
        } catch (e) { 
            Swal.fire({
                title: "Upload file Failed!",
                icon: "error",
                allowOutsideClick: true,
            })
            console.log("error:", e) }

    }

    const dropzone = () => {
        return (
            <div>
                <Dropzone
                    onChange={updateFiles}
                    value={files}
                    actionButtons={{
                        position: "after",
                        abortButton: {},
                    }}
                >
                    {files.map((file) => (
                        <FileMosaic key={file.id} {...file} onDelete={removeFile} info />
                    ))}
                </Dropzone>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={()=>uploadButtonHandler()} color="blue" className="btn btn-primary m-2" style={{ padding: '7px 16px', fontSize: '13px' }}>Upload</Button>
                    <Button onClick={() => setIsShowDropzone(false)} color="blue" className="btn btn-primary m-2" style={{ padding: '7px 16px', fontSize: '13px' }} >Return Back</Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            {   
                isShowDropzone?
                dropzone():
                <div>
                    <div  style={{ verticalAlign: 'top', textAlign: 'center' }}>
                        <head>
                            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap" rel="stylesheet"/>
                        </head>
                        {process.env.REACT_APP_NODE_ENV==="PROD"?<h5><strong style={{ fontWeight: 700}}>{displayStatus}</strong></h5>:<div>{status}{"(FuncID: " + funcID + ","}{" txnID: " + txnID }{" preTxnID: " + preTxnID + " material: "+material+")"}</div>}
                    </div>
                    <div style={{ background: 'rgba(128, 128, 128, 0.1)', padding: '10px', borderRadius: '10px' }}>
                        {getApproverSection()} 
                        {isShowSubmitBtn() ? getSubmitButtonSection() : null}
                        <div>
                            <small>
                                {status && status !== "SUBMIT_TXN" && status !== "INITIAL_NOT_SUBMIT" && (
                                isValidUrl(txnInfo) ? (
                                    <div>
                                    <a
                                        style={{ color: "DodgerBlue" }}
                                        onClick={() => { window.open(txnInfo, "_blank"); }}
                                        onMouseEnter={(e) => { e.target.style.cursor = "pointer"; }}
                                    >
                                        View report
                                    </a>
                                    </div>
                                ) : (
                                    <div>Submit data: {txnInfo}</div>
                                )
                                )}
                            </small>
                            <small>
                                {status && status !== "SUBMIT_TXN" && status !== "INITIAL_NOT_SUBMIT" && (
                                <div>Submit time: {timestamp}</div>
                                )}
                            </small>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default TxnItem;