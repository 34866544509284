import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

const OverviewTab = (props) => {
    const { data,setSelectedBatchID, setActiveTab } = props;

    const row = data["batches"]?.map((d) => {
        return (
            <tr key={d["batchID"]}>
                <th scope="row">{d["HSBatchNum"]}</th>
                <td>{d["material"]}</td>
                <td>{d["NameOfMaterialSupplier"]}</td>
                <td>{d["Quantity"]+""}</td>
                <td>{d["Specification"]}</td>
                <td>{d["InvoiceNum"]}</td>
                <td><div className="link-success" onClick={()=>{
                    setSelectedBatchID(d["batchID"]);
                    setActiveTab("2");
                }}>
                <Link to={"/project-overview?id="+data["ID"]+"&name="+data["HSProjectID"]+"&tab=2"+"&Bid="+d["batchID"]} className="link-success">
                View More <i className="ri-arrow-right-line align-middle"></i>
                </Link>
                </div></td>
            </tr>
        );
    })

    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <h6 className="mb-3 text-uppercase">Summary</h6>
                                <div>Contractor : <span className="fw-medium">{data["NameOfConstructor"]}</span></div>
                                <div>Test Lab : <span className="fw-medium">{data["NameOfSite"]}</span></div>
                                <div>DefyPay In Charge : <span className="fw-medium">{data["NameOfInChargeFromDefypay"]}</span></div>
                                <div>Site Address : <span className="fw-medium">{data["AddressOfSite"]}</span></div>
                                <div>Test Lab : <span className="fw-medium">{data["NameOfTestingLab"]}</span></div>

                                <Row style={{marginTop: 30}}>
                                    <Col lg={12}>
                                        <div className="table-responsive table-card">
                                            <Table className="table-borderless align-middle mb-0">
                                                <thead className="table-light">
                                                    <tr>                                                        
                                                        <th scope="col">HS Batch No.</th>
                                                        <th scope="col">Material</th>
                                                        <th scope="col">Supplier</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Specification</th>
                                                        <th scope="col">Invoice</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;