import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import UiContent from '../../../Components/Common/UiContent';
import { APPROVERS, contract, transactionParameters } from '../../../SmartContract';
import { performTransaction } from '../performTransaction';

function getCurrentDateTimeString() {
    const now = new Date();

    // Get the individual components of the date
    const year = now.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    // Concatenate the components to form the desired string
    const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return dateTimeString;
}

const CreateProject = () => {
    document.title = "Create Project | DefyPay";
    const navigate = useNavigate();
    const [isShowDialog, setShowDialog] = useState(false);

    const [state, setState] = useState({
        hsProjectID: "TestingID_" + getCurrentDateTimeString(),
        nameOfContractor: "Testing Contractor Name",
        nameOfTestLab: "Testing Lab Name",
        nameOfDefypayInCharge: "Testing DefyPay",
        nameOfSite: "Testing Site Name",
        siteAddress: "Testing Site Address",
    })

    const onChangeHandler = (e, name) => {
        if (name === "hsProjectID") {
            setState({ ...state, hsProjectID: e.target.value })
        }
        else if (name === "nameOfContractor") {
            setState({ ...state, nameOfContractor: e.target.value })
        }
        else if (name === "nameOfDefypayInCharge") {
            setState({ ...state, nameOfDefypayInCharge: e.target.value })
        }
        else if (name === "nameOfTestLab") {
            setState({ ...state, nameOfTestLab: e.target.value })
        }
        else if (name === "nameOfSite") {
            setState({ ...state, nameOfSite: e.target.value })
        }
        else if (name === "siteAddress") {
            setState({ ...state, siteAddress: e.target.value })
        }
    }

    const callTest = async () => {
        const ACTION = [
            {
                "name": "SAMPLE_SUMBIT",
                "approvers": [APPROVERS.WORKER, APPROVERS.HS_WORKER],
                "numOfExecutes": 2,
                "numOfConfirmations": 2
            },
            {
                "name": "SAMPLE_TRANSFER",
                "approvers": [APPROVERS.WORKER, APPROVERS.HS_WORKER],
                "numOfExecutes": 2,
                "numOfConfirmations": 2
            },
            {
                "name": "SAMPLE_ARRIVEL_LAB",
                "approvers": [APPROVERS.DEFYPAY,APPROVERS.LAB],
                "numOfExecutes": 2,
                "numOfConfirmations": 2
            },
            {
                "name": "SAMPLE_SCAN",
                "approvers": [APPROVERS.LAB],
                "numOfExecutes": 2,
                "numOfConfirmations": 1
            },
            {
                "name": "SAMPLE_REPORT_SUMBIT",
                "approvers": [APPROVERS.LAB, APPROVERS.DEFYPAY],
                "numOfExecutes": 2,
                "numOfConfirmations": 2
            }
        ]
        console.log(JSON.stringify(ACTION));

        // Define your function parameters
        const functionParameters = [
            state.hsProjectID,
            state.nameOfContractor,
            state.nameOfTestLab,
            state.nameOfDefypayInCharge,
            state.nameOfSite,
            state.siteAddress,
            ACTION
        ];

        performTransaction(functionParameters,"createProject");     
        setShowDialog(true);
    }

    return (
        <React.Fragment>
            <UiContent />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create Project" pageTitle="Project" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="card-body">
                                    <Row className="gy-4">
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">HS Project ID</Label>
                                                <Input type="text" className="form-control" id="hsProjectID" name="hsProjectID" value={state.hsProjectID} onChange={(e) => onChangeHandler(e, 'hsProjectID')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Name of Contractor</Label>
                                                <Input type="text" className="form-control" id="nameOfContractor" name="nameOfContractor" value={state.nameOfContractor} onChange={(e) => onChangeHandler(e, 'nameOfContractor')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Name of Test Lab</Label>
                                                <Input type="text" className="form-control" id="nameOfTestLab" name="nameOfTestLab" value={state.nameOfTestLab} onChange={(e) => onChangeHandler(e, 'nameOfTestLab')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Name of DefyPay In Charge</Label>
                                                <Input type="text" className="form-control" id="nameOfDefypayInCharge" name="nameOfDefypayInCharge" value={state.nameOfDefypayInCharge} onChange={(e) => onChangeHandler(e, 'nameOfDefypayInCharge')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Name of Site</Label>
                                                <Input type="text" className="form-control" id="nameOfSite" name="nameOfSite" value={state.nameOfSite} onChange={(e) => onChangeHandler(e, 'nameOfSite')} />
                                            </div>
                                        </Col>
                                        <Col xxl={6} md={12}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Site Address</Label>
                                                <Input type="text" className="form-control" id="siteAddress" name="siteAddress" value={state.siteAddress} onChange={(e) => onChangeHandler(e, 'siteAddress')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs={12} className='text-align-center'>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => { callTest() }}>Create Project</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal isOpen={isShowDialog} toggle={() => {
                navigate("/project-list");
            }} centered={true}>
                <ModalBody className="d-flex flex-column">
                    <div>The project is created.</div>
                    <Button className="align-self-end mt-2" color="primary" onClick={() => {
                        setShowDialog(false);
                        navigate("/project-list");
                    }}> OK </Button>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default CreateProject;