import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/lib/persistReducer';

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";

const persistConfig = {
    key: "root",
    devTools: true,
    storage,
  }
  
const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer
});

const persistedReducer = persistReducer(persistConfig,rootReducer);
  
export default persistedReducer;