//file run on import
import ProjectManagementContract from './smartContracts/ProjectManagementContract.json';
import TransactionContract from './smartContracts/TransactionContract.json';
import { BrowserProvider } from 'ethers';

export const APPROVERS = {
	"DEFYPAY": {
		name: "DEFYPAY",
		role: "ADMIN",
		ac: "0x26306D7428CcC2C909485b8988992201d22D519C"
	},
	"WORKER": {
		name: "WORKER",
		role: "USER",
		ac: "0x27D461E71Aaa6b7330357d7E77B8e50720B24EEF"
	},
	"HS_WORKER": {
		name: "HS",
		role: "USER",
		ac: "0x0Fbc9B022d6B295e28fD95999FDdfcd0eCa76d60"
	},
	"LAB": {
		name: "LAB",
		role: "USER",
		ac: "0x8D0ac3e48c16C0d637EdaF16a0cAa080529D441A"
	}
}

export const APPROVERS_MAP = {};
for (const key in APPROVERS) {
	const user = APPROVERS[key];
	APPROVERS_MAP[user.ac] = user;
}

const { ethers } = require('ethers');

console.log("NETWORK_URL: ",process.env.REACT_APP_NETWORK_URL)

export const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
export const contractAbi = ProjectManagementContract.output.abi;

export const contractDefypayAddress = process.env.REACT_APP_DEFYPAY_CONTRACT_ADDRESS;
export const contractDefypayAbi =TransactionContract.output.abi;

export var provider = null;
export var signer = null;
export var contract = null;
export var signContract = null;
export var contractDefypay = null;
export var signContractDefypay = null;

//avoid missing parameter in declaring provider
//may cause infinite loading time when metamask extension is not installed/locked
try{
	//check if metamask installed
	if(window.ethereum)
	{	
		//check if the metamask locked
		const accounts = await window.ethereum.request({ method: 'eth_accounts' });

    	if (accounts.length !== 0) {
			provider =new BrowserProvider(window.ethereum);
			signer = await provider.getSigner();
			contract = new ethers.Contract(contractAddress, contractAbi, provider);
			signContract = new ethers.Contract(contractAddress, contractAbi,signer);
			contractDefypay = new ethers.Contract(contractDefypayAddress, contractDefypayAbi, provider);
			signContractDefypay = new ethers.Contract(contractDefypayAddress, contractDefypayAbi, signer);
		}else{
			console.log("metamask locked")
		}
	}
}catch(e)
{console.log("provider cant set:",e)}

console.log(provider);
console.log(signer);

// no need to update provider, since provide only update on network update & 
// network update to correct network=> file reload & provider auto update 

export const updateSigner = async (account) => {
	try{
		signer = await provider.getSigner(account);
		console.log("updateSigner")
		signContract = new ethers.Contract(contractAddress, contractAbi,signer);
		signContractDefypay = new ethers.Contract(contractDefypayAddress, contractDefypayAbi, signer);
		console.log(signer)
	}catch(e)
	{
		console.log("updateSigner error",e)
	}
}

export const fetchChainID = async() => {
	try{
		const chainID = await window.ethereum.request({ method: "eth_chainId" });
		console.log("chainId",chainID)
		return chainID;
	}catch(e)
	{console.log("failed fetchChainID",e)}
}
