import React, { useEffect, useState } from 'react';


//import Components
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap';
import UiContent from '../../../Components/Common/UiContent';
import { APPROVERS, contract, signContract } from '../../../SmartContract';
import { useLocation, useNavigate } from 'react-router-dom';
import { performTransaction } from '../performTransaction';

function getCurrentDateTimeString() {
    const now = new Date();

    // Get the individual components of the date
    const year = now.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    // Concatenate the components to form the desired string
    const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return dateTimeString;
}

const CreateBatch = () => {
    document.title = "Create Batch | DefyPay";
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const location = useLocation();
    const [isShowDialog, setShowDialog] = useState(false);

    const materialList = ["Cement","steel","concrete"]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await signContract.getProjects(APPROVERS.DEFYPAY.ac);
                setData(result);
            } catch (error) {
                console.error('Error calling contract method:', error);
            }
        };

        fetchData();
    }, []);

    const [state, setState] = useState({
        projectID: location.state?.id !== undefined?parseInt(location.state?.id):"",
        hsBatchNo: "TestingBatchID_" + getCurrentDateTimeString(),
        material: "Cement",
        supplier: "Testing Supplier Name",
        specification: "Example spec",
        quantity: 1000,
        invoiceNo: "Invoice No",
    })

    const projectMap = new Map();

    const projects = data.map((d)=> {
        let id = parseInt(d["ID"]);
        projectMap.set(id, d["HSProjectID"]);
        return (
            <DropdownItem key={id} onClick={() => handleItemClick(id)} className={state.projectID === id ? 'selected' : ''}>{d["HSProjectID"]}</DropdownItem>
        );
    })

    const onChangeHandler = (e, name) => {
        if (name === "hsBatchNo") {
            setState({ ...state, hsBatchNo: e.target.value })
        }
        else if (name === "supplier") {
            setState({ ...state, supplier: e.target.value })
        }
        else if (name === "specification") {
            setState({ ...state, specification: e.target.value })
        }
        else if (name === "quantity") {
            setState({ ...state, quantity: e.target.value })
        }
        else if (name === "invoiceNo") {
            setState({ ...state, invoiceNo: e.target.value })
        }
    }

    const handleItemClick = (item) => {
        setState({ ...state, projectID: item })
    };

    const callTest = async () => {
        const functionParameters = [
            state.projectID,
            state.material,
            state.supplier,
            state.specification,
            parseInt(state.quantity),
            state.hsBatchNo,
            state.invoiceNo
        ]; 
        performTransaction(functionParameters,"createBatch");    
        setShowDialog(true); 
    }

    const materials = materialList?.map((d,i) => {
        return (
            <DropdownItem key={i} onClick={() => setState({...state,material:d})} >{d}</DropdownItem>
        );
    })

    return (
        <React.Fragment>
            <UiContent />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create Batch" pageTitle="Project" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="card-body">
                                    <Row className="gy-4">
                                        <Col xxl={12} md={12}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">HS Project ID</Label>
                                                <UncontrolledDropdown style={{minWidth: 300}}>
                                                    <DropdownToggle tag="button" className="btn btn-primary" id="dropdownMenuButton">
                                                        {state.projectID !== ""? projectMap.get(state.projectID):"Select Project to Create Batch"} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {projects}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">HS Batch No.</Label>
                                                <Input type="text" className="form-control" id="hsBatchNo" name="hsBatchNo" value={state.hsBatchNo} onChange={(e) => onChangeHandler(e, 'hsBatchNo')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Material</Label>
                                                <UncontrolledDropdown style={{ minWidth: 300 }}>
                                                    <DropdownToggle tag="button" className="btn btn-primary" id="dropdownMenuButton">
                                                        {state.material} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    {materialList.length > 0 ?
                                                        <DropdownMenu>
                                                            {materials}
                                                        </DropdownMenu> : null}
                                                </UncontrolledDropdown>
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Supplier</Label>
                                                <Input type="text" className="form-control" id="supplier" name="supplier" value={state.supplier} onChange={(e) => onChangeHandler(e, 'supplier')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Specification</Label>
                                                <Input type="text" className="form-control" id="specification" name="specification" value={state.specification} onChange={(e) => onChangeHandler(e, 'specification')} />
                                            </div>
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Quantity</Label>
                                                <Input type="number" className="form-control" id="quantity" name="quantity" value={state.quantity} onChange={(e) => onChangeHandler(e, 'quantity')} />
                                            </div>
                                        </Col>
                                        <Col xxl={6} md={12}>
                                            <div>
                                                <Label htmlFor="basiInput" className="form-label">Invoice No.</Label>
                                                <Input type="text" className="form-control" id="invoiceNo" name="invoiceNo" value={state.invoiceNo} onChange={(e) => onChangeHandler(e, 'invoiceNo')} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs={12} className='text-align-center'>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" onClick={()=>{callTest()}}>Create Batch</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal isOpen={isShowDialog} toggle={()=>{
                navigate("/project-list");
            }} centered={true}>
                <ModalBody className="d-flex flex-column">
                    <div>The batch is created.</div>                    
                    <Button className="align-self-end mt-2" color="primary" onClick={()=>{
                        setShowDialog(false);
                        navigate("/project-list");
                    }}> OK </Button>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default CreateBatch;