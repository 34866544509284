import React, { useEffect, useState } from 'react';
import {Link } from 'react-router-dom';
import {Input, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { contract, contractDefypay,signContractDefypay} from '../../../SmartContract';
import TxnItem from './TxnItem';

const color = ['#a264da', '#59c37d', '#eea751', '#90e2ef', '#e344de'];
const functionToDisplayName = [
    {
        "function": "SAMPLE_SUMBIT",
        "displayName": "Submit New Sample"
    },
    {
        "function": "SAMPLE_TRANSFER",
        "displayName": "Transfer Sample"
    },
    {
        "function": "SAMPLE_ARRIVEL_LAB",
        "displayName": "Sample Arrival at Lab"
    },
    {
        "function": "SAMPLE_SCAN",
        "displayName": "Scan Sample"
    },
    {
        "function": "SAMPLE_REPORT_SUMBIT",
        "displayName": "Submit Sample Report"
    }
];

const DocumentsTab = (props) => {
    const { data, selectedBatchID, setSelectedBatchID } = props;
    const [functionList, setFunctionList] = useState([]);
    const [material, setMaterial] = useState("undefined");
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        if (data !== undefined) {
            const fetchData = async () => {
                try {
                    let id = data["ID"];
                    console.log("call getFunctions " + id);
                    const result = await contract.getFunctions(id);
                    
                    console.log("getFunctions");
                    console.log(result);
                    setFunctionList(result);
                } catch (error) {
                    console.error('Error calling contract method:', error);
                }                
            };
            if (data["ID"] !== undefined && data["ID"] !== "") {
                fetchData();
            }
        }
    }, [data])

    useEffect(() => {
        console.log("useEffect")
        let txnResult = [];
    
        const fetchData = async (batchID, txnID, previousTxnArray) => {
            try {
                console.log("batchID: " + batchID + " | txnID: " + txnID);
                const result = await signContractDefypay.getTransactionByBatchIDAndPreTxnID(batchID, txnID);
                console.log("Result:");
                console.log(result);
                
                let newResult = [...result];
                if (Number(result[0]["txnID"]) !== 0) {
                    functionList.map((_func, index) => {
                        if (_func[0] === result[0]["funcID"]&&_func["numOfExecute"]>result.length) {
                            const newSubmission = {
                                0: Number(result[0]["funcID"]),
                                1: false,
                                2: 'SUBMIT_TXN',
                                3: Number(result[0]["preTxnID"]),
                                4: 0,
                            };
                            newResult.push(newSubmission)
                            return;
                        }
                    })
                }
                //used for loop instead of mapping=> map does handle async function properly
                for (const transaction of newResult) {
                    let newTxnArray = [...previousTxnArray, transaction];
                    console.log("newTxnArray", newTxnArray);
                    if (transaction.txnID && transaction.txnID + "" !== '0') {
                        await fetchData(batchID, transaction.txnID, newTxnArray);
                    } else {
                        console.log("txnResult", txnResult);
                        txnResult.push(newTxnArray);
                    }
                }
    
            } catch (error) {
                console.error('Error calling contract method:', error);
            }
        };
    
        if (selectedBatchID !== undefined && selectedBatchID !== "" && functionList.length > 0) {
            setTransactions([]);
            fetchData(selectedBatchID, 0, []).then(() => {
                setTransactions(txnResult);
            }); // Start with index 0 and then setTransactions after fetchData completes
        }
    }, [selectedBatchID, contractDefypay, functionList]);

    useEffect(()=>{data["batches"]?.map((d)=>{
        if(d["batchID"]===selectedBatchID)
            setMaterial(d["material"]);
        })
    },[selectedBatchID,data])

    const batchMap = new Map();

    const batches = data["batches"]?.map((d) => {
        batchMap.set(d["batchID"], d["HSBatchNum"]);
        return (
            <Link to={"/project-overview?id="+data["ID"]+"&name="+data["HSProjectID"]+"&tab=2"+"&Bid="+`${d["batchID"]}`} className="link-success">
                <DropdownItem key={d["batchID"]} onClick={() => setSelectedBatchID(d["batchID"])} className={selectedBatchID === d["batchID"] ? 'selected' : ''}>{d["HSBatchNum"]}</DropdownItem>
            </Link>
        );
    })
    console.log("functionList",functionList)
    const functionHeader = functionList.map((d, index) => {
        // Find the matching display name
        const displayNameObj = functionToDisplayName.find(item => item.function === d.name);
        const displayName = displayNameObj ? displayNameObj.displayName : d.name;
    
        // Calculate the width based on the number of functions
        const width = `${100 / functionList.length}%`;
    
        return (
            <th 
                scope="col" 
                key={index} 
                style={{ 
                    backgroundColor: color[index], 
                    color: 'white', 
                    textAlign: 'center',
                    width: width,
                    padding: '10px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
                title={displayName} // Add a title attribute for tooltip on hover
            >
                {displayName}
            </th>
        );
    });

    const row = data["batches"]?.map((d, index) => {
          if (d["batchID"] === selectedBatchID) {
            return (
              <tr key={index}>
                <th scope="row">{d["HSBatchNum"]}</th>
                <td>{d["material"]}</td>
                <td>{d["NameOfMaterialSupplier"]}</td>
                <td>{d["Quantity"] + ""}</td>
                <td>{d["Specification"]}</td>
                <td>{d["InvoiceNum"]}</td>
              </tr>
            );
          }
          return null;
    });

    console.log(selectedBatchID);
    console.log(batchMap);
    console.log("transactions",transactions);

    
    const rowTxn = () => {
        let occurrence = [];
        return (
            transactions?.map((txn, index) => {
                let txnType;
                console.log("occurrence",occurrence)
                try {
                    console.log("transaction type", txn[index]);
                    txnType = txn[index][2];
                } catch (e) {
                    console.log("error", e);
                    console.log(txn[index]);
                }
                return (
                    <tr key={txn["txnID"]}>
                        <td key={index}>{index}</td>
                        {
                            functionList.map((_f, idx) => { // Changed index to idx
                                let isOccured = 0; 
                                occurrence.map((o)=>{
                                    console.log("o === txn[idx]",o,o==txn[idx])
                                    if(o === txn[idx])
                                        isOccured =1;
                                })
                                if (txn.length > idx&&!isOccured) {
                                    occurrence.push(txn[idx]);
                                    return (
                                        <td key={idx} style={{ verticalAlign: 'top' }}>
                                            <TxnItem 
                                                txn={txn[idx]} 
                                                approvers={functionList[idx]["approvers"]} 
                                                projectID={data["ID"]} 
                                                batchID={selectedBatchID} 
                                                batchMaterial={material}
                                            />
                                        </td>
                                    );
                                } else {
                                    return (<td key={idx}></td>);
                                }
                            })
                        }
                    </tr>
                );
            })
        );
    };
 

    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardBody>
                            <Col xxl={12} md={12}>
                                <div className="d-flex flex-row align-items-start">
                                    <UncontrolledDropdown style={{ minWidth: 300 }}>
                                        <DropdownToggle tag="button" className="btn btn-primary" id="dropdownMenuButton">
                                            {selectedBatchID !== "" && selectedBatchID !== undefined ? batchMap.get(selectedBatchID) : "Select a Batch No."} <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        {data["batches"] !== undefined && Object.keys(data["batches"]).length > 0 ?
                                            <DropdownMenu>
                                                {batches}
                                            </DropdownMenu> : null}
                                    </UncontrolledDropdown>
                                </div>
                            </Col>
                            {selectedBatchID !== "" && selectedBatchID !== undefined ?
                                <div className="text-muted">
                                    <Row style={{ marginTop: 30 }}>
                                        <Col lg={12}>
                                            <div className="table-responsive table-card">
                                                <Table className="table-borderless align-middle mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">HS Batch No.</th>
                                                            <th scope="col">Material</th>
                                                            <th scope="col">Supplier</th>
                                                            <th scope="col">Quantity</th>
                                                            <th scope="col">Specification</th>
                                                            <th scope="col">Invoice</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {row}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                        <div className="mb-3 border-top border-top-dashed mt-4" />
                                        <div className="live-preview">
                                            <div className="table-responsive">
                                                <Table className="align-middle table-nowrap mb-0">
                                                    <thead>
                                                        <tr>    
                                                            <th scope="col" style={{ backgroundColor: '#6691e7' }}></th>
                                                            {functionHeader}
                                                        </tr>
                                                    </thead>                                                                                                                 
                                                    <tbody>
                                                        {rowTxn()} 
                                                        {/* {isShowNewTxn()?newTxnRow():null}*/}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row>
                                </div> : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DocumentsTab;