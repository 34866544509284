import React, { useEffect, useState, useRef } from 'react';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Card, CardBody, Container, Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { APPROVERS, contract } from '../../../SmartContract';

const ProjectList = () => {
    document.title = "Project List | Defypay";
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));
    const [data, setData] = useState([]);    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await contract.getProjects(user.account);
                setData(result);
            } catch (error) {
                console.error('Error calling contract method:', error);
            }
        };

        fetchData();
    }, [user]);

    const row = data.map((d) => {
        return (
            <tr key={d["ID"]}>
                <th scope="row">{d["HSProjectID"]}</th>
                <td>{d["NameOfConstructor"]}</td>
                <td>{d["NameOfSite"]}</td>
                <td>{d["AddressOfSite"]}</td>
                <td>{d["NameOfTestingLab"]}</td>
                <td>{d["NameOfInChargeFromDefypay"]}</td>
                <td><Link to={"/project-overview?id="+d["ID"]+"&name="+d["HSProjectID"]+"&tab=1"} className="link-success">View More <i className="ri-arrow-right-line align-middle"></i></Link></td>
            </tr>
        );
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Project List" pageTitle="Projects" />
                    <Card>
                        <CardBody>
                            <div className="live-preview">
                                <div className="table-responsive">
                                    <Table className="align-middle table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">HS Project ID</th>
                                                <th scope="col">Contractor</th>
                                                <th scope="col">Test Lab</th>
                                                <th scope="col">DefyPay In Charge</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Site Address</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {row}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectList;